import React from 'react';

import { H3, MEDIA_QUERY } from '../../commonStyles';
import { getLink } from '../../constants/links';
import { DetailsVariant } from './DetailsVariant';
import { ImageVariant } from './ImageVariant';
import { useProductVariant } from './useProductVariant';
import { useIsProductKoko } from './useIsProductKoko';
import { ProductViewContainer, VariantContainer } from './ui';
import styled from 'styled-components';

interface ProductViewProps {
  product: Record<string, any>;
  lang: string;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  current: boolean;
}

const ProductTitle = styled(H3)(
  { transition: 'opacity 0.3s ease-in-out', textAlign: 'center' },
  ({ current }: { current: boolean }) => ({
    [MEDIA_QUERY.TABLET_AND_MOBILE]: {
      opacity: current ? 1 : 0,
    },
  })
);

export const ProductViewMobile: React.FC<ProductViewProps> = ({
  product,
  lang,
  index,
  isFirst,
  isLast,
  current,
  openProductDescriptionPopup,
  openProductIngredientPopup,
}) => {
  const {
    selectedVariantIndex,
    selectedProductVariant,
    prevProductVariant,
    nextProductVariant,
    addProductVariantToShoppingCart,
    changeVariantSize,
    sizes,
  } = useProductVariant({ product });
  const { sizeType } = product;
  const isProductKoko = useIsProductKoko({ product });
  return (
    <>
      <ProductViewContainer marginTopDesktop={index === 0 ? '42px' : '0'}>
        <ProductTitle current={current} margin={'0 0 12px 0'}>
          {product.title}
        </ProductTitle>
        <VariantContainer>
          <ImageVariant
            current={current}
            isFirst={isFirst}
            isLast={isLast}
            to={`${getLink(lang, 'productDescription')}/${product.slug}`}
            product={product}
            selectedVariantIndex={selectedVariantIndex}
            openProductDescriptionPopup={openProductDescriptionPopup}
          />
          <DetailsVariant
            selectedVariantIndex={selectedVariantIndex}
            changeVariantSize={changeVariantSize}
            sizes={sizes}
            sizeType={sizeType}
            current={current}
            lang={lang}
            onClickMinus={prevProductVariant}
            onClickPlus={nextProductVariant}
            onClickAddToShoppingCart={addProductVariantToShoppingCart}
            productVariant={selectedProductVariant}
            isProductKoko={isProductKoko}
            openProductIngredientPopup={openProductIngredientPopup}
          />
        </VariantContainer>
      </ProductViewContainer>
    </>
  );
};
