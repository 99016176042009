import React from 'react';
import Slider from 'react-slick';
import { MEDIA_QUERY_MIN_RESOLUTIONS } from '../../commonStyles';

const globalSettings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  infinite: false,
  centerPadding: '144px',
  responsive: [
    {
      breakpoint: MEDIA_QUERY_MIN_RESOLUTIONS.TABLET,
      settings: {
        centerPadding: '22px',
      },
    },
  ],
};

interface Props {
  activeIndex?: number;
  afterChange?: (currentSlide: number) => void;
}

export class PreventScrollOnSwipe extends React.Component<Props> {
  private firstClientX: number;
  private clientX: number;

  public constructor(props: Props) {
    super(props);

    this.firstClientX = 0;
    this.clientX = 0;
  }

  public componentDidMount() {
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, { passive: false });
  }

  public componentWillUnmount() {
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch);
  }

  public touchStart(e: any) {
    this.firstClientX = e.touches[0].clientX;
  }

  public preventTouch(e: any) {
    const treshold = 5;

    this.clientX = e.touches[0].clientX - this.firstClientX;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > treshold && e.cancelable) {
      e.preventDefault();
      e.returnValue = false;
    }
  }
  public render() {
    return null;
  }
}

export const ProductsSlider: React.FC<Props> = ({
  children,
  activeIndex,
  afterChange,
}) => {
  const slider = React.useRef<Slider | null>(null);
  React.useEffect(() => {
    if (slider.current && activeIndex !== undefined) {
      slider.current.slickGoTo(activeIndex);
    }
  }, [activeIndex, slider.current]);
  const settings = {
    ...globalSettings,
    afterChange,
  };
  return (
    <>
      <PreventScrollOnSwipe />
      <Slider ref={slider} {...settings}>
        {children}
      </Slider>
    </>
  );
};
