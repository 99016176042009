import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../commonStyles';
import { getLink } from '../constants/links';

interface FooterBoxProps {
  language: string;
}

const footerBoxData = (language: string) => {
  const { allContentfulFooter } = useStaticQuery(graphql`
    query AlternativeFooterBoxQuery {
      allContentfulFooter {
        nodes {
          node_locale
          alternativeFooterBoxTitle
        }
      }
    }
  `);
  return allContentfulFooter.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

type Data = {
  alternativeFooterBoxTitle: string;
};

const AboutServiceFooter = styled.div`
  padding-top: 48px;
  padding-bottom: 20px;
  margin: 0 auto;
  text-align: center;
  ${MEDIA_QUERY.DESKTOP} {
    padding-top: 92px;
  }
  ${MEDIA_QUERY.MOBILE} {
    padding-top: 30px;
  }
`;

const StyledLink = styled(Link)`
  font-family: Arial;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1px;
  color: #000000;
  text-decoration: none;
  line-height: 72px;
  height: 72px;
  border: solid 2px #000000;
  display: inline-block;
  width: 480px;
  text-align: center;
  box-sizing: border-box;
  :active {
    background-color: ${COLORS.LIGHT_DARK_GREY};
    border-color: ${COLORS.LIGHT_DARK_GREY};
    color: ${COLORS.WHITE};
    transition: all 0.2s linear;
  }
  @media (hover: hover) {
    @media not all and (any-pointer: coarse) {
      :hover {
        background-color: ${COLORS.LIGHT_DARK_GREY};
        border-color: ${COLORS.LIGHT_DARK_GREY};
        color: ${COLORS.WHITE};
        transition: all 0.2s linear;
      }
    }
  }
  ${MEDIA_QUERY.TABLET} {
    width: 448px;
  }
  ${MEDIA_QUERY.MOBILE} {
    font-size: 16px;
    width: 255px;
    height: 45px;
    line-height: 45px;
  }
`;

export const AboutServiceFooterBox: React.FC<FooterBoxProps> = ({
  language,
}) => {
  const { alternativeFooterBoxTitle }: Data = footerBoxData(language);

  return (
    <AboutServiceFooter>
      <StyledLink to={`${getLink(language, 'aboutService')}`}>
        {alternativeFooterBoxTitle}
      </StyledLink>
    </AboutServiceFooter>
  );
};
