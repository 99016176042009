import React from 'react';
import styled from 'styled-components';
import { ProductVariant } from './types';
import {
  KokoCircles,
  BlueNumberCircle,
  OrangeNumberCircle,
  GreenNumberCircle,
  MEDIA_QUERY_MIN_RESOLUTIONS,
  MEDIA_QUERY,
} from '../../commonStyles';
import { useKokoModel } from './useKokoModel';

interface Props {
  isProductKoko: boolean;
  productVariant: ProductVariant;
}

export const Ingredients: React.FC<Props> = ({
  isProductKoko,
  productVariant,
  openProductIngredientPopup,
}) => {
  const dishCollection = productVariant.dishCollection || [];
  const kokoModel = useKokoModel({ productVariant });
  return (
    <ProductIngredientsContainer>
      {!isProductKoko &&
        dishCollection.map((dish: Record<string, any>, index: number) => {
          const amountText = dish.amount === 0 ? '' : `${dish.amount} ×`;
          return (
            <React.Fragment key={`${dish.dish.slug}_${index}`}>
              <PopupLink
                onClick={() => openProductIngredientPopup(dish.dish.id)}
              >
                <ProductIngredient>
                  {amountText} {dish.dish.type}
                </ProductIngredient>
              </PopupLink>
              <hr />
            </React.Fragment>
          );
        })}
      {isProductKoko &&
        Object.keys(kokoModel)
          .map(key => kokoModel[key])
          .map((dish: any, index) => {
            return (
              <React.Fragment key={index}>
                <ProductIngredient>
                  <PopupLink
                    onClick={() =>
                      openProductIngredientPopup(dish.idOfBlueVersion)
                    }
                  >
                    {dish.amountTotal} × {dish.typeOfDish}
                  </PopupLink>
                  <KokoCircles>
                    {dish.amountOfGreenDishes !== 0 && (
                      <PopupLink
                        onClick={() =>
                          openProductIngredientPopup(dish.idOfGreenVersion)
                        }
                      >
                        <GreenNumberCircle>
                          {dish.amountOfGreenDishes}
                        </GreenNumberCircle>
                      </PopupLink>
                    )}
                    {dish.amountOfOrangeDishes !== 0 && (
                      <PopupLink
                        onClick={() =>
                          openProductIngredientPopup(dish.idOfOrangeVersion)
                        }
                      >
                        <OrangeNumberCircle>
                          {dish.amountOfOrangeDishes}
                        </OrangeNumberCircle>
                      </PopupLink>
                    )}
                    {dish.amountOfBlueDishes !== 0 && (
                      <PopupLink
                        onClick={() =>
                          openProductIngredientPopup(dish.idOfBlueVersion)
                        }
                      >
                        <BlueNumberCircle>
                          {dish.amountOfBlueDishes}
                        </BlueNumberCircle>
                      </PopupLink>
                    )}
                  </KokoCircles>
                </ProductIngredient>
                <hr />
              </React.Fragment>
            );
          })}
    </ProductIngredientsContainer>
  );
};

const ProductIngredientsContainer = styled.div`
  padding-top: 16px;
  @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    padding-top: 10px;
  }
  @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}px) {
    padding-top: 16px;
  }
  hr {
    border: 0;
    border-top: solid 1px #bcbcbc;
    :last-child {
      display: none;
    }
  }
  a {
    text-decoration: none;
    font-size: 16px;
    font-family: Arial;
    line-height: 1.5;
    color: #232425;
    @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
      font-size: 18px;
      line-height: 1.33;
    }
  }
  ${MEDIA_QUERY.TABLET} {
    hr {
      color: #d2d2d2;
    }
  }
`;

const ProductIngredient = styled.div`
  background-image: url('../../img/arrow.svg');
  background-position: right center;
  background-repeat: no-repeat;
  padding-top: 5px;
  padding-bottom: 1px;
  text-align: left;
  ${MEDIA_QUERY.TABLET} {
    padding-top: 17px;
    padding-bottom: 15px;
  }
  ${MEDIA_QUERY.MOBILE} {
    padding-top: 4px;
    padding-bottom: 0;
  }
  a {
    transition: color 0.2s linear;
  }
  :hover {
    a {
      color: rgba(0, 0, 0, 0.4);
      transition: color 0.2s linear;
    }
  }
`;

const PopupLink = styled.span`
  cursor: pointer;
`;
