import { useState, useMemo } from 'react';
import { useAddToShoppingCart } from './useAddToshoppingCart';
import { VariantSize } from './DetailsVariant';
import { ProductVariant } from '../../types/basicTypes';

export const useProductVariant = ({
  product,
}: {
  product: Record<string, any>;
}) => {
  const [currentlySelectedVariant, setCurrentlySelectedVariant] = useState(0);
  const selectedProductVariant = useMemo(() => {
    return product.variant[currentlySelectedVariant];
  }, [product, currentlySelectedVariant]);

  function prevProductVariant(): void {
    if (currentlySelectedVariant > 0)
      setCurrentlySelectedVariant(currentlySelectedVariant - 1);
  }

  function nextProductVariant(): void {
    if (currentlySelectedVariant < product.variant.length - 1)
      setCurrentlySelectedVariant(currentlySelectedVariant + 1);
  }

  function changeVariantSize(size: VariantSize) {
    setCurrentlySelectedVariant(size.variantIndex);
  }

  const addProductVariantToShoppingCart = useAddToShoppingCart({
    product,
    selectedVariant: currentlySelectedVariant,
  });

  const sizes = product.variant.map(
    (v: ProductVariant, variantIndex: number): VariantSize => ({
      size: v.size,
      variantIndex,
    })
  );

  return {
    selectedVariantIndex: currentlySelectedVariant,
    selectedProductVariant,
    prevProductVariant,
    nextProductVariant,
    changeVariantSize,
    addProductVariantToShoppingCart,
    sizes,
  };
};
