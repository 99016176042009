import React, { useState } from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { MEDIA_QUERY_MIN_RESOLUTIONS, MEDIA_QUERY } from '../../commonStyles';
import ImageModal from '../imageModal';

interface Props {
  product: Record<string, any>;
}
interface ThumbnailClickHandlerArgs {
  imageNumber: number;
  imageUrl: string;
}

export const Gallery: React.FC<Props> = ({ product }) => {
  const [shouldShowImageModal, setShouldShowImageModal] = useState(false);
  const [currentlySelectedImageUrl, setCurrentlySelectedImageUrl] = useState(
    ''
  );
  const [
    currentlySelectedImageNumber,
    setCurrentlySelectedImageNumber,
  ] = useState(0);
  const setShowModal = (state: boolean) => {
    const overflow = state ? 'hidden' : 'unset';
    document.body.style.overflow = overflow;
    setShouldShowImageModal(state);
  };

  function handleClickOnThumbnail({
    imageNumber,
    imageUrl,
  }: ThumbnailClickHandlerArgs): void {
    setCurrentlySelectedImageNumber(imageNumber);
    setCurrentlySelectedImageUrl(imageUrl);
    setShowModal(true);
  }

  function handleRequestForPreviousImage(): void {
    if (currentlySelectedImageNumber > 0) {
      setCurrentlySelectedImageUrl(
        product.photos[currentlySelectedImageNumber - 1].file.url
      );
      setCurrentlySelectedImageNumber(currentlySelectedImageNumber - 1);
    }
  }

  function handleRequestForNextImage(): void {
    if (currentlySelectedImageNumber < 2) {
      setCurrentlySelectedImageUrl(
        product.photos[currentlySelectedImageNumber + 1].file.url
      );
      setCurrentlySelectedImageNumber(currentlySelectedImageNumber + 1);
    }
  }

  return (
    <>
      <ImageContainer>
        <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}>
          {(matches): JSX.Element => {
            if (matches) {
              return (
                <>
                  <FirstImageInGallery
                    src={product.photos[0].file.url + '?w=1024'}
                    alt={product.title}
                    onClick={() =>
                      handleClickOnThumbnail({
                        imageNumber: 0,
                        imageUrl: product.photos[0].file.url,
                      })
                    }
                  />
                  <OtherImageInGallery
                    src={product.photos[1].file.url + '?w=1024'}
                    alt={product.title}
                    onClick={() =>
                      handleClickOnThumbnail({
                        imageNumber: 1,
                        imageUrl: product.photos[1].file.url,
                      })
                    }
                  />
                  <OtherImageInGallery
                    src={product.photos[2].file.url + '?w=1024'}
                    alt={product.title}
                    onClick={() =>
                      handleClickOnThumbnail({
                        imageNumber: 2,
                        imageUrl: product.photos[2].file.url,
                      })
                    }
                  />
                </>
              );
            } else {
              return (
                <>
                  <FirstImageInGallery
                    src={product.photos[0].file.url + '?w=1024'}
                    alt={product.title}
                  />
                  <OtherImageInGallery
                    src={product.photos[1].file.url + '?w=1024'}
                    alt={product.title}
                  />
                  <OtherImageInGallery
                    src={product.photos[2].file.url + '?w=1024'}
                    alt={product.title}
                  />
                </>
              );
            }
          }}
        </MediaQuery>
      </ImageContainer>
      {process.title === 'browser' ? (
        <ImageModal
          shouldBeVisible={shouldShowImageModal}
          setShouldShowImageModal={setShowModal}
          imageUrl={currentlySelectedImageUrl}
          requestPreviousImage={handleRequestForPreviousImage}
          requestNextImage={handleRequestForNextImage}
        />
      ) : null}
    </>
  );
};

const ImageContainer = styled.div`
  margin-top: 48px;
  white-space: normal;
  ${MEDIA_QUERY.MOBILE} {
    margin-top: -48px;
  }
`;

const FirstImageInGallery = styled.img`
  @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}px) {
    width: 480px;
    height: 288px;
    object-fit: cover;
    cursor: pointer;
  }
`;

const OtherImageInGallery = styled.img`
  padding-top: 30px;
  @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}px) {
    width: 224px;
    height: 288px;
    margin-left: 32px;
    object-fit: cover;
    cursor: pointer;
    padding-top: 0;
  }
`;
