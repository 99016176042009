import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { uniqWith, isEqual } from 'lodash';
import { Category, Product } from '../../types/basicTypes';
import { ProductPickerMenuCategories } from './menu-category';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';
import { ProductPickerMenuProducts } from './menu-product';
import { normalizeTitle } from '../../functions/utils';

const ProductPickerMenuContainer = styled.div({
  marginTop: '12px',
  backgroundColor: COLORS.WHITE,
  maxWidth: '1440px',
  marginLeft: 'auto',
  marginRight: 'auto',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    position: 'fixed',
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 3,
  },
  [MEDIA_QUERY.DESKTOP]: {
    marginTop: '-43px',
  },
});

type ProductPickerMenuProps = {
  scrollToProduct: (productTitle: string, smooth: boolean) => void;
  visibleProduct: Product | null;
  allProducts: Product[];
  onCategoryClick: (category: Category) => void;
  getCategoryFirst: (category: Category) => Product | null;
};

export const getCategories = (products: Product[]): Category[] =>
  uniqWith(
    products.map(p => p.category),
    isEqual
  );

export const ProductPickerMenu: React.FC<ProductPickerMenuProps> = ({
  scrollToProduct,
  visibleProduct,
  allProducts,
  onCategoryClick,
  getCategoryFirst,
}) => {
  const [categoryClicked, setCategoryClicked] = useState(false);
  const [productClicked, setProductClicked] = useState(false);
  const allCategories = getCategories(allProducts);
  const visibleCategory = visibleProduct?.category;
  const [selectedCategory, selectCategory] = useState<Category>(
    allCategories[0]
  );

  const activeCategory = categoryClicked
    ? selectedCategory
    : visibleCategory || selectedCategory;

  const products = allProducts.filter(p => isEqual(activeCategory, p.category));
  const [selectedProduct, selectProduct] = useState<Product>(products[0]);
  const activeProduct = productClicked
    ? selectedProduct
    : visibleProduct || selectedProduct;

  const [transitionBlocked, setTransitionBlock] = useState(false);
  let scrollTimeout = 0;

  addEventListener('scroll', function() {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function() {
      setTransitionBlock(false);
    }, 300);
  });

  useEffect(() => {
    if (!transitionBlocked) {
      setProductClicked(false);
      setCategoryClicked(false);
    }
  }, [visibleCategory?.id, visibleProduct?.id]);

  return (
    <ProductPickerMenuContainer>
      <ProductPickerMenuCategories
        onClick={category => {
          setTransitionBlock(true);
          setCategoryClicked(true);
          setProductClicked(true);
          const categoryFirst = getCategoryFirst(category);
          if (categoryFirst) {
            selectProduct(categoryFirst);
          }
          selectCategory(category);
          onCategoryClick(category);
        }}
        categories={allCategories}
        activeCategory={activeCategory}
      />
      <ProductPickerMenuProducts
        activeCategory={activeCategory}
        onClick={product => {
          setTransitionBlock(true);
          setProductClicked(true);
          selectProduct(product);
          scrollToProduct(normalizeTitle(product.title), true);
        }}
        products={products}
        activeProduct={activeProduct}
      />
    </ProductPickerMenuContainer>
  );
};
