import React from 'react';
import styled from 'styled-components';
import { Product } from './types';
import { MEDIA_QUERY_MIN_RESOLUTIONS } from '../../commonStyles';

interface Props {
  product: Product;
}

export const GalleryMobile: React.FC<Props> = ({ product }) => (
  <ImageList key={product.id}>
    {product.photos.map(photo => (
      <ImageElement
        src={`${photo.file.url}?fm=jpg&fl=progressive&fit=fill&w=${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}`}
        alt={product.title}
        key={photo.file.url}
      />
    ))}
  </ImageList>
);

const ImageElement = styled.img`
  width: 100%;
  vertical-align: middle;
  &:not(:first-child) {
    padding-top: 30px;
  }
`;

const ImageList = styled.div`
  width: 100%;
  display: block;
`;
