import React, { Component, Fragment } from 'react';
import { H4, COLORS, MEDIA_QUERY } from '../../commonStyles';
import styled from 'styled-components';

import backSvg from '../../assets/images/back.svg';

interface ProductDescriptionPopupProps {
  product: Record<string, any>;
}

interface ProductDescriptionPopupState {
  containerState: string | null;
}

class ProductDescriptionPopup extends Component<
  ProductDescriptionPopupProps,
  ProductDescriptionPopupState
> {
  public constructor(props: ProductDescriptionPopupProps) {
    super(props);

    this.containerRef = React.createRef();
    this.openingRAF = null;
    this.closingRAF = null;

    this.state = {
      containerState: null,
    };
  }

  public componentDidMount(): void {
    document.body.style = 'overflow: hidden;';
    // DO NOT REMOVE this line - this makes sure that DOM styles are ready before state is changed
    getComputedStyle(this.containerRef.current).transform;
    this.openingRAF = window.requestAnimationFrame(this.openingStart);
    this.containerRef.current.addEventListener(
      'transitionend',
      this.openingEnd,
      false
    );
  }

  public componentWillUnmount(): void {
    document.body.style = 'overflow: visible;';
    this.containerRef.current.removeEventListener(
      'transitionend',
      this.openingEnd,
      false
    );
    this.containerRef.current.removeEventListener(
      'transitionend',
      this.closingEnd,
      false
    );
    window.cancelAnimationFrame(this.openingRAF);
    window.cancelAnimationFrame(this.closingRAF);
  }

  private openingStart = (): void => {
    // DO NOT REMOVE this line - this makes sure that DOM styles are ready before state is changed
    getComputedStyle(this.containerRef.current).transform;
    this.setState({
      containerState: 'opening',
    });
  };

  private openingEnd = (e): void => {
    if (e.target === this.containerRef.current) {
      this.containerRef.current.removeEventListener(
        'transitionend',
        this.openingEnd,
        false
      );
      this.setState({
        containerState: 'opened',
      });
    }
  };

  private closingStart = (): void => {
    this.setState({
      containerState: 'closing',
    });
  };

  private closingEnd = (e): void => {
    if (e.target === this.containerRef.current) {
      this.containerRef.current.removeEventListener(
        'transitionend',
        this.closingEnd,
        false
      );
      this.setState({
        containerState: 'closed',
      });
      this.props.closeProductDescriptionPopup();
    }
  };

  private close = (): void => {
    this.containerRef.current.addEventListener(
      'transitionend',
      this.closingEnd,
      false
    );
    this.closingRAF = window.requestAnimationFrame(this.closingStart);
  };

  public render(): JSX.Element {
    const { product } = this.props;
    const photo = product.photos[0].file.url;

    return (
      <Fragment>
        <ContainerOverlay />
        <Container
          animation={this.state.containerState}
          ref={this.containerRef}
        >
          <Layout>
            <ProductDescriptionHeader>
              <H3>{product.description_caption}</H3>
              <BackLink onClick={this.close}>
                <img src={backSvg} alt="Go back to the Product" />
              </BackLink>
            </ProductDescriptionHeader>
            <ImageAndDescription key={`product`}>
              <ImageContainerDesktop>
                <Image
                  src={`${photo}?fm=jpg&fl=progressive&fit=fill&w=960&h=960`}
                  alt={product.title}
                />
              </ImageContainerDesktop>
              <ImageContainerTablet>
                <Image
                  src={`${photo}?fm=jpg&fl=progressive&fit=fill&w=688`}
                  alt={product.title}
                />
              </ImageContainerTablet>
              <ImageContainerMobile>
                <Image
                  src={`${photo}?fm=jpg&fl=progressive&fit=fill&w=315&h=315`}
                  alt={product.title}
                />
              </ImageContainerMobile>
              <DescriptionContainer>
                <H4>{product.title}</H4>
                <Description id={'description'}>
                  {product.description.description}
                </Description>
              </DescriptionContainer>
            </ImageAndDescription>
            {product.designer.map(
              (designer: Record<string, any>, index: number): JSX.Element => {
                const photo = designer.photos.file;
                return (
                  <ImageAndDescription key={`${designer.title}_${index}`}>
                    <ImageContainerDesktop>
                      <Image
                        src={`${photo.url}?fm=jpg&fl=progressive&f=faces&fit=fill&w=480`}
                        alt={designer.title}
                      />
                    </ImageContainerDesktop>
                    <ImageContainerTablet>
                      <Image
                        src={`${photo.url}?fm=jpg&fl=progressive&fit=fill&w=688`}
                        alt={designer.title}
                      />
                    </ImageContainerTablet>
                    <ImageContainerMobile>
                      <Image
                        src={`${photo.url}?fm=jpg&fl=progressive&fit=fill&w=315`}
                        alt={designer.title}
                      />
                    </ImageContainerMobile>
                    <DescriptionContainer>
                      <H4>{designer.title}</H4>
                      <Description id={'designerdescription' + index}>
                        {designer.description.description}
                      </Description>
                    </DescriptionContainer>
                  </ImageAndDescription>
                );
              }
            )}
          </Layout>
        </Container>
      </Fragment>
    );
  }
}

const ContainerOverlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  background: 'rgba(0,0,0,0.7)',
  zIndex: 999,
});

const Container = styled.div(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: '#ffffff',
    zIndex: 1000,
    transition: 'transform 0.7s ease',
    transform: 'translate(-100%, 0)',
    overflow: 'hidden',
    [MEDIA_QUERY.TABLET]: {
      transitionDuration: '0.5s',
    },
    [MEDIA_QUERY.MOBILE]: {
      transitionDuration: '0.3s',
    },
  },
  ({ animation }) => {
    switch (animation) {
      case 'opening': {
        return {
          transform: 'translate(0, 0)',
          overflow: 'hidden',
        };
      }
      case 'opened': {
        return {
          transform: 'translate(0, 0)',
          overflow: 'auto',
        };
      }
      case 'closing': {
        return {
          transform: 'translate(-100%, 0)',
          overflow: 'hidden',
        };
      }
      case 'closed': {
        return {
          transform: 'translate(-100%, 0)',
          overflow: 'hidden',
        };
      }
      default: {
        return {};
      }
    }
  }
);

const Layout = styled.div({
  margin: '0 auto',
  width: '1248px',
  boxSizing: 'border-box',
  ['@media (max-width: 1439px)']: {
    width: '100%',
    padding: '0 96px',
    boxSizing: 'border-box',
  },
  ['@media (max-width: 1279px)']: {
    width: '992px',
    margin: '0 auto',
    padding: 0,
  },
  [MEDIA_QUERY.TABLET]: {
    width: '100%',
    margin: 0,
    padding: '0 40px',
    boxSizing: 'border-box',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: '100%',
    margin: 0,
    padding: '0 15px',
    boxSizing: 'border-box',
  },
});

const ProductDescriptionHeader = styled.div({
  display: 'flex',
  width: '100%',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '84px 0 39px',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  [MEDIA_QUERY.TABLET]: {
    padding: '42px 0',
  },
  [MEDIA_QUERY.MOBILE]: {
    padding: '28px 0 20px',
  },
});

const H3 = styled.h3({
  fontFamily: 'Arial',
  fontSize: '36px',
  fontWeight: 'bold',
  lineHeight: '44px',
  color: COLORS.BLACK,
  margin: '0',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '24px',
    lineHeight: '32px',
    padding: '0 15px',
  },
});

const BackLink = styled.span({
  opacity: 1,
  cursor: 'pointer',
  transition: 'opacity 0.4s ease',
  ':hover': {
    opacity: 0.4,
  },
});

const ImageAndDescription = styled.div({
  padding: '48px 0',
  borderBottom: `1px solid ${COLORS.BLACK}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  [MEDIA_QUERY.TABLET]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
  [MEDIA_QUERY.MOBILE]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    padding: '30px 15px',
  },
});

const ImageContainerDesktop = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  maxWidth: '480px',
  maxHeight: '480px',
  overflow: 'hidden',
  [MEDIA_QUERY.TABLET]: {
    display: 'none',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'none',
  },
});

const ImageContainerTablet = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  maxWidth: '688px',
  maxHeight: '432px',
  overflow: 'hidden',
  [MEDIA_QUERY.DESKTOP]: {
    display: 'none',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'none',
  },
});

const ImageContainerMobile = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  maxWidth: '315px',
  maxHeight: '315px',
  overflow: 'hidden',
  [MEDIA_QUERY.DESKTOP]: {
    display: 'none',
  },
  [MEDIA_QUERY.TABLET]: {
    display: 'none',
  },
});

const Image = styled.img`
  width: ${props => props.width || '100%'};
`;

const DescriptionContainer = styled.div({
  width: '480px',
  marginLeft: '32px',
  [MEDIA_QUERY.TABLET]: {
    margin: '0 0 38px',
    width: 'auto',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '0 0 30px',
    width: 'auto',
  },
});

const Description = styled.div({
  fontFamily: 'Arial',
  fontSize: '18px',
  lineHeight: '28px',
  color: COLORS.DARK_GREY,
  paddingTop: '28px',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
    lineHeight: '24px',
    paddingTop: '8px',
  },
});

export default ProductDescriptionPopup;
