import React from 'react';
import MediaQuery from 'react-responsive';

import { H3, MEDIA_QUERY_MIN_RESOLUTIONS } from '../../commonStyles';
import { DetailsVariant } from './DetailsVariant';
import { Gallery } from './Gallery';
import { ImageVariant } from './ImageVariant';
import { useProductVariant } from './useProductVariant';
import { useIsProductKoko } from './useIsProductKoko';
import { ProductViewContainer, VariantContainer, Hr } from './ui';
import { normalizeTitle } from '../../functions/utils';

interface ProductViewProps {
  product: Record<string, any>;
  lang: string;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  current: boolean;
}

export const ProductView: React.FC<ProductViewProps> = ({
  product,
  lang,
  index,
  isFirst,
  isLast,
  current,
  openProductDescriptionPopup,
  openProductIngredientPopup,
}) => {
  const {
    selectedVariantIndex,
    selectedProductVariant,
    prevProductVariant,
    nextProductVariant,
    addProductVariantToShoppingCart,
    sizes,
    changeVariantSize,
  } = useProductVariant({ product });
  const isProductKoko = useIsProductKoko({ product });
  const { sizeType } = product;

  return (
    <>
      <ProductViewContainer marginTopDesktop={index === 0 ? '42px' : '0'}>
        <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}>
          {(matches): JSX.Element | null =>
            matches ? (
              <span
                id={normalizeTitle(product.title)}
                style={{
                  color: 'white',
                  position: 'relative',
                  bottom: '171px',
                  zIndex: -100,
                }}
              >
                .
              </span>
            ) : null
          }
        </MediaQuery>
        <H3 margin={'0 0 12px 0'}>{product.title}</H3>
        <VariantContainer>
          <ImageVariant
            current={current}
            isFirst={isFirst}
            isLast={isLast}
            product={product}
            selectedVariantIndex={selectedVariantIndex}
            openProductDescriptionPopup={openProductDescriptionPopup}
          />
          <DetailsVariant
            selectedVariantIndex={selectedVariantIndex}
            changeVariantSize={changeVariantSize}
            sizeType={sizeType}
            lang={lang}
            sizes={sizes}
            onClickMinus={prevProductVariant}
            onClickPlus={nextProductVariant}
            onClickAddToShoppingCart={addProductVariantToShoppingCart}
            productVariant={selectedProductVariant}
            isProductKoko={isProductKoko}
            openProductIngredientPopup={openProductIngredientPopup}
          />
        </VariantContainer>
        <Gallery product={product} />
      </ProductViewContainer>
      <Hr />
    </>
  );
};
