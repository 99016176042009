import React from 'react';
import styled from 'styled-components';
import { COLORS, CONTAINERS, MEDIA_QUERY } from '../../commonStyles';
import { Category } from '../../types/basicTypes';

export type MenuCategory = {
  name: string;
  id: string;
};

const MenuCategoryContainer = styled.button<{ active: boolean }>(
  {
    outline: 'none',
    border: 'none',
    fontSize: '16px',
    padding: '16px',
    width: '33.33%',
    cursor: 'pointer',
    color: COLORS.LIGHT_BLACK,
    backgroundColor: COLORS.GREY_V3,
    transition: 'background-color 0.3s linear',
    borderTop: `2px solid ${COLORS.GREY_V3}`,
    [MEDIA_QUERY.TABLET]: {
      marginTop: '-54px',
    },
  },
  ({ active }) => {
    const activeStyles = {
      backgroundColor: COLORS.WHITE,
      fontWeight: 'bold',
      color: COLORS.BLACK,
      [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
        zIndex: 3,
      },
    };

    return active ? activeStyles : {};
  }
);

const CategoriesContainer = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  [MEDIA_QUERY.DESKTOP]: {
    maxWidth: CONTAINERS.DESKTOP,
    margin: 'auto',
  },
});

type ProductPickerMenuCategoriesProps = {
  categories: Category[];
  activeCategory: Category;
  onClick: (category: Category) => void;
};

export const ProductPickerMenuCategories: React.FC<ProductPickerMenuCategoriesProps> = ({
  categories,
  onClick,
  activeCategory,
}) => {
  return (
    <CategoriesContainer>
      {categories.map(category => (
        <MenuCategoryContainer
          active={activeCategory.id === category.id}
          onClick={() => onClick(category)}
        >
          {category.name}
        </MenuCategoryContainer>
      ))}
    </CategoriesContainer>
  );
};
