import { graphql } from 'gatsby';
import { get } from 'lodash/fp';
import React, { Component } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { MEDIA_QUERY } from '../../commonStyles';
import { Layout } from '../../components';
import { AboutServiceFooterBox } from '../../components/AboutServiceFooterBox';
import {
  GalleryMobile,
  ProductView,
  ProductViewMobile,
} from '../../components/ProductView';
import ProductDescriptionPopup from './ProductDescriptionPopup';
import ProductIngredientPopup from './ProductIngredientPopup';
import { ProductsSlider } from './ProductsSlider';
import {
  getCategories,
  ProductPickerMenu,
} from '../../components/product-menu';
import { makeQuery, normalizeTitle } from '../../functions/utils';
import { isEqual, flatten } from 'lodash';
import { Category, Product } from '../../types/basicTypes';

const ProductPickerContainer = styled.div({
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
});

interface ProductPickerProps {
  data: Record<string, any>;
  location: Record<string, string>;
  pageContext: Record<string, string>;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface ProductPickerState {
  currentlyProductTitle: string;
  productDescriptionIndex: number | null;
  productIngredientId: string | null;
  visibleProduct: Product | null;
}

export const pageQuery = graphql`
  query ProductQuery($language: String!) {
    allContentfulProductPicker(filter: { node_locale: { eq: $language } }) {
      nodes {
        id
        categories {
          category {
            name
          }
          title
          products {
            title
            id
            slug
            sizeType
            category {
              name
              id
              contentful_id
            }
            basketImage {
              file {
                url
              }
            }
            photos {
              file {
                url
              }
            }
            variant {
              id
              size
              name
              skuCode
              price
              images {
                file {
                  url
                }
              }
              dishCollection {
                amount
                dish {
                  id
                  title
                  type
                  slug
                  kokoType
                }
              }
            }
            description {
              description
            }
            description_caption
            designer {
              title
              photos {
                file {
                  url
                }
              }
              description {
                description
              }
            }
          }
        }
      }
    }
    allContentfulProductIngredient(filter: { node_locale: { eq: $language } }) {
      nodes {
        id
        title
        type
        description {
          description
        }
        photo {
          sizes {
            sizes
          }
          file {
            url
          }
        }
        careInstructions {
          description
          pictogram {
            file {
              url
            }
          }
          id
        }
        careInstructionTitle
        series
        kokoType
        koKoBlueVersion {
          id
        }
        koKoOrangeVersion {
          id
        }
        koKoGreenVersion {
          id
        }
      }
    }
  }
`;

const getProductIngredients = get('data.allContentfulProductIngredient.nodes');

class ProductPickerTemplate extends Component<
  ProductPickerProps,
  ProductPickerState
> {
  public constructor(props: ProductPickerProps) {
    super(props);
    this.state = {
      visibleProduct: null,
      currentlyProductTitle: '',
      productDescriptionIndex: null,
      productIngredientId: null,
    };
  }

  private getNodes = () => {
    const getData = get('allContentfulProductPicker.nodes[0]');
    const data = getData(this.props.data).categories.map(c => c.products);
    return flatten(data);
  };

  private productTitles: string[] = [];

  public componentDidMount(): void {
    this.onHashChange(this.props.location.hash, this.state);
    this.scrollToProduct(this.props.location.hash.slice(1), false);

    window.addEventListener('scroll', this.listenToScroll);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  public componentDidUpdate(
    prevProps: ProductPickerProps,
    prevState: ProductPickerState
  ) {
    if (this.props.location.hash !== prevProps.location.hash) {
      this.onHashChange(this.props.location.hash, prevState);
    }
  }

  private getCurrentlyProduct = () => {
    const nodes = this.getNodes();

    return nodes.find((product: any) => {
      return (
        product.title &&
        this.getNormalizedProductTitle(product.title) ===
          this.state.currentlyProductTitle
      );
    });
  };

  private onHashChange = (hash: string, state: ProductPickerState) => {
    const title = hash.substr(1);

    const category = getCategories(this.getNodes()).find(
      category => normalizeTitle(category.name) === title
    );

    if (category) {
      this.scrollToCategoryFirst(category);
    } else if (title !== state.currentlyProductTitle) {
      const visibleProduct = this.getNodes().find(
        n => normalizeTitle(n.title) === title
      );
      this.setState({
        currentlyProductTitle: title,
        visibleProduct,
      });
    }
  };

  private getNormalizedProductTitle = (productTitle: string): string => {
    return normalizeTitle(productTitle);
  };

  private updateHashLocation = (normalizedProductTitle: string) => {
    window.history.pushState(null, '', `#${normalizedProductTitle}`);
  };

  private scrollToProduct = (
    normalizedProductTitle: string,
    smooth: boolean
  ): void => {
    if (
      normalizedProductTitle !== undefined &&
      normalizedProductTitle.length > 0
    ) {
      const productView = document.getElementById(normalizedProductTitle);
      if (productView !== undefined) {
        setTimeout((): void => {
          if (productView) {
            productView.scrollIntoView({
              behavior: smooth ? 'smooth' : 'auto',
              block: 'start',
              inline: 'nearest',
            });
          }
        }, 100);
      }
    }
  };

  private setCurrentlyProduct = (
    productTitle?: string,
    visibleProduct?: Product
  ): void => {
    if (productTitle) {
      const normalizedProductTitle = this.getNormalizedProductTitle(
        productTitle
      );
      if (this.state.currentlyProductTitle !== normalizedProductTitle) {
        if (visibleProduct) {
          this.setState({
            visibleProduct,
            currentlyProductTitle: normalizedProductTitle,
          });
        } else {
          this.setState({
            currentlyProductTitle: normalizedProductTitle,
          });
        }

        this.updateHashLocation(normalizedProductTitle);
      }
    }
  };

  private setCurrentlyProductByIndex = (index: number): void => {
    const productTitle = this.productTitles[index];
    const visibleProduct = this.getNodes()[index];
    this.setCurrentlyProduct(productTitle, visibleProduct);
  };

  public listenToScroll = (): void => {
    if (this.props.isDesktop) {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const productViewHeight = 1011;
      const offsetNeededToAdjustMomentOfSwitch = 300;

      let indexOfCurrentlyProductSeries = Math.min(
        this.productTitles.length - 1,
        Math.floor(
          (winScroll + offsetNeededToAdjustMomentOfSwitch) / productViewHeight
        )
      );

      const doesUserReachedBottom =
        document.documentElement.scrollHeight -
          document.documentElement.scrollTop ===
        document.documentElement.clientHeight;
      if (
        doesUserReachedBottom ||
        indexOfCurrentlyProductSeries > this.productTitles.length
      ) {
        indexOfCurrentlyProductSeries = this.productTitles.length - 1;
      }

      this.setCurrentlyProductByIndex(indexOfCurrentlyProductSeries);
    }
  };

  private getProductsNames = (): string[] => {
    const nodes = this.getNodes();
    if (!nodes) {
      return [];
    }
    return nodes.map((product: Record<any, any>) => product.title);
  };

  private renderProducts = () => {
    const nodes = this.getNodes();

    return nodes.map(
      (product: Record<any, any>, index: number): JSX.Element => {
        const normalizedProductTitle = this.getNormalizedProductTitle(
          product.title
        );
        return (
          <ProductView
            product={product}
            key={product.id}
            lang={this.props.pageContext.language}
            index={index}
            isFirst={index === 0}
            isLast={index === nodes.length - 1}
            current={
              normalizedProductTitle === this.state.currentlyProductTitle
            }
            openProductDescriptionPopup={() =>
              this.openProductDescriptionPopup(index)
            }
            openProductIngredientPopup={this.openProductIngredientPopup}
          />
        );
      }
    );
  };

  private renderMobileProducts = (activeIndex: number) => {
    const nodes = this.getNodes();
    return nodes.map(
      (product: Record<any, any>, index: number): JSX.Element => {
        return (
          <ProductViewMobile
            product={product}
            key={product.id}
            lang={this.props.pageContext.language}
            index={index}
            isFirst={index === 0}
            isLast={index === nodes.length - 1}
            current={index === activeIndex}
            openProductDescriptionPopup={() =>
              this.openProductDescriptionPopup(index)
            }
            openProductIngredientPopup={this.openProductIngredientPopup}
          />
        );
      }
    );
  };

  private mapProductTitleToIndex = (productTitles: string[], name: string) => {
    return productTitles.findIndex(
      productTitle => this.getNormalizedProductTitle(productTitle) === name
    );
  };

  private openProductDescriptionPopup = (index: number) => {
    this.setState({
      productDescriptionIndex: index,
    });
  };

  private closeProductDescriptionPopup = () => {
    this.setState({
      productDescriptionIndex: null,
    });
  };

  private openProductIngredientPopup = (id: number) => {
    this.setState({
      productIngredientId: id,
    });
  };

  private closeProductIngredientPopup = () => {
    this.setState({
      productIngredientId: null,
    });
  };

  private getCategoryFirst = (category: Category): Product | null => {
    const nodes = this.getNodes();
    const first: Product | undefined = nodes.find(n =>
      isEqual(n.category, category)
    );
    return first ? first : null;
  };

  private scrollToCategoryFirst = (category: Category) => {
    const first = this.getCategoryFirst(category);
    if (first) {
      const normalizedTitle = normalizeTitle(first.title);
      const index = this.mapProductTitleToIndex(
        this.productTitles,
        normalizedTitle
      );
      if (index !== -1) {
        if (this.props.isDesktop) {
          this.scrollToProduct(normalizedTitle, true);
        }
        this.setCurrentlyProductByIndex(index);
      }
    }
  };

  public render(): JSX.Element {
    const { pathContext, isDesktop, isMobile } = this.props;
    const { language } = pathContext;
    const products = this.getNodes();

    this.productTitles = this.getProductsNames();
    const defaultIndex = index => {
      if (index === -1 && products.length > 0) {
        return 0;
      } else {
        return index;
      }
    };
    const productIndex = defaultIndex(
      this.mapProductTitleToIndex(
        this.productTitles,
        this.state.currentlyProductTitle
      )
    );
    const currentlyProduct = this.getCurrentlyProduct();
    const productIngredients = getProductIngredients(this.props);
    let productIngredient = null;
    if (this.state.productIngredientId !== null) {
      productIngredient = productIngredients.find(
        ({ id }) => id === this.state.productIngredientId
      );
      if (typeof productIngredient === 'undefined') {
        productIngredient = null;
      }
    }

    const title = products[productIndex] ? products[productIndex].title : '';
    return (
      <Layout
        title={title}
        language={language}
        headerHideShadow={false}
        hideFooterBox
      >
        <ProductPickerContainer>
          <ProductPickerMenu
            getCategoryFirst={(category: Category) =>
              this.getCategoryFirst(category)
            }
            allProducts={products}
            visibleProduct={this.state.visibleProduct}
            onCategoryClick={category => {
              this.scrollToCategoryFirst(category);
            }}
            scrollToProduct={(productTitle, smooth) => {
              this.setCurrentlyProductByIndex(
                this.mapProductTitleToIndex(this.productTitles, productTitle)
              );
              this.scrollToProduct(productTitle, smooth);
            }}
          />
          <ProductsContainer>
            {!isDesktop && (
              <>
                <ProductsSlider
                  activeIndex={productIndex}
                  afterChange={this.setCurrentlyProductByIndex}
                >
                  {this.renderMobileProducts(productIndex)}
                </ProductsSlider>
                {currentlyProduct && (
                  <GalleryMobile product={products[productIndex]} />
                )}
              </>
            )}
            {isDesktop && <ProductsList>{this.renderProducts()}</ProductsList>}
            <AboutServiceFooterBox language={language} />
          </ProductsContainer>
          {this.state.productDescriptionIndex !== null && (
            <ProductDescriptionPopup
              language={language}
              product={products[this.state.productDescriptionIndex]}
              closeProductDescriptionPopup={this.closeProductDescriptionPopup}
            />
          )}
          {productIngredient !== null && (
            <ProductIngredientPopup
              language={language}
              productIngredient={productIngredient}
              closeProductIngredientPopup={this.closeProductIngredientPopup}
              openProductIngredientPopup={this.openProductIngredientPopup}
            />
          )}
        </ProductPickerContainer>
      </Layout>
    );
  }
}

const ProductsContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  ${MEDIA_QUERY.TABLET} {
    margin-top: 100px;
  }
`;

const ProductsList = styled.div({
  position: 'relative',
  width: '100%',
  [MEDIA_QUERY.DESKTOP]: {
    margin: 'auto',
    display: 'flex',
    maxWidth: '992px',
    flexDirection: 'column',
  },
});

const isDesktopHOC = (Component: React.ComponentType<ProductPickerProps>) => (
  props: Omit<ProductPickerProps, 'isDesktop'>
) => {
  /**
   * isClient is used here for intentionally render
   * this page different on the server and the client
   * more explanation: https://reactjs.org/docs/react-dom.html#hydrate
   */
  const isDesktop = useMediaQuery({ query: makeQuery(MEDIA_QUERY.DESKTOP) });
  const isTablet = useMediaQuery({ query: makeQuery(MEDIA_QUERY.TABLET) });
  const isMobile = useMediaQuery({ query: makeQuery(MEDIA_QUERY.MOBILE) });
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) {
    return null;
  }

  return (
    <Component
      {...props}
      isMobile={isMobile}
      isTablet={isTablet}
      isDesktop={isDesktop}
    />
  );
};

export default isDesktopHOC(ProductPickerTemplate);
