import React, { Component, Fragment } from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import ReactImageMagnify from 'react-image-magnify';
import {
  BlueNumberCircle,
  GreenNumberCircle,
  H3,
  OrangeNumberCircle,
  MEDIA_QUERY_MIN_RESOLUTIONS,
  MEDIA_QUERY,
} from '../../commonStyles';

import backSvg from '../../assets/images/back.svg';

interface ProductIngredientProps {
  productIngredient: Record<string, any>;
  language: string;
}

class ProductIngredientPopup extends Component<ProductIngredientProps> {
  public constructor(props: ProductDescriptionPopupProps) {
    super(props);

    this.containerRef = React.createRef();
    this.openingRAF = null;
    this.closingRAF = null;

    this.state = {
      containerState: null,
    };
  }

  public componentDidMount(): void {
    document.body.style = 'overflow: hidden;';
    // DO NOT REMOVE this line - this makes sure that DOM styles are ready before state is changed
    getComputedStyle(this.containerRef.current).transform;
    this.openingRAF = window.requestAnimationFrame(this.openingStart);
    this.containerRef.current.addEventListener(
      'transitionend',
      this.openingEnd,
      false
    );
  }

  public componentWillUnmount(): void {
    document.body.style = 'overflow: visible;';
    this.containerRef.current.removeEventListener(
      'transitionend',
      this.openingEnd,
      false
    );
    this.containerRef.current.removeEventListener(
      'transitionend',
      this.closingEnd,
      false
    );
    window.cancelAnimationFrame(this.openingRAF);
    window.cancelAnimationFrame(this.closingRAF);
  }

  private openingStart = (): void => {
    // DO NOT REMOVE this line - this makes sure that DOM styles are ready before state is changed
    getComputedStyle(this.containerRef.current).transform;
    this.setState({
      containerState: 'opening',
    });
  };

  private openingEnd = (e): void => {
    if (e.target === this.containerRef.current) {
      this.containerRef.current.removeEventListener(
        'transitionend',
        this.openingEnd,
        false
      );
      this.setState({
        containerState: 'opened',
      });
    }
  };

  private closingStart = (): void => {
    this.setState({
      containerState: 'closing',
    });
  };

  private closingEnd = (e): void => {
    if (e.target === this.containerRef.current) {
      this.containerRef.current.removeEventListener(
        'transitionend',
        this.closingEnd,
        false
      );
      this.setState({
        containerState: 'closed',
      });
      this.props.closeProductIngredientPopup();
    }
  };

  private close = (): void => {
    this.containerRef.current.addEventListener(
      'transitionend',
      this.closingEnd,
      false
    );
    this.closingRAF = window.requestAnimationFrame(this.closingStart);
  };

  private getGreenColorCircle(): JSX.Element {
    if (this.props.productIngredient.koKoGreenVersion !== null) {
      return (
        <span
          onClick={() =>
            this.props.openProductIngredientPopup(
              this.props.productIngredient.koKoGreenVersion.id
            )
          }
        >
          <KoKoCirclesContainer>
            <GreenCircle />
          </KoKoCirclesContainer>
        </span>
      );
    }
    return (
      <KoKoCirclesContainer>
        <SelectedColorKoKo>
          <GreenCircle />
        </SelectedColorKoKo>
      </KoKoCirclesContainer>
    );
  }

  private getOrangeColorCircle(): JSX.Element {
    if (this.props.productIngredient.koKoOrangeVersion !== null) {
      return (
        <span
          onClick={() =>
            this.props.openProductIngredientPopup(
              this.props.productIngredient.koKoOrangeVersion.id
            )
          }
        >
          <KoKoCirclesContainer>
            <OrangeCircle />
          </KoKoCirclesContainer>
        </span>
      );
    }
    return (
      <KoKoCirclesContainer>
        <SelectedColorKoKo>
          <OrangeCircle />
        </SelectedColorKoKo>
      </KoKoCirclesContainer>
    );
  }

  private getBlueColorCircle(): JSX.Element {
    if (this.props.productIngredient.koKoBlueVersion !== null) {
      return (
        <span
          onClick={() =>
            this.props.openProductIngredientPopup(
              this.props.productIngredient.koKoBlueVersion.id
            )
          }
        >
          <BlueCircle />
        </span>
      );
    }
    return (
      <SelectedColorKoKo>
        <BlueCircle />
      </SelectedColorKoKo>
    );
  }

  public render(): JSX.Element {
    const { productIngredient } = this.props;

    const smallSize = 480;
    const mediumSize = 1000;
    const largeSize = 1600;

    const imageSrc = `${productIngredient.photo.file.url}?w=${smallSize}`;
    const imageSetSrc = `${productIngredient.photo.file.url +
      '?w=' +
      smallSize}, ${productIngredient.photo.file.url +
      '?w=' +
      mediumSize} 2x, ${productIngredient.photo.file.url} 3x`;

    const shouldColorChoiceBeVisible = !(
      productIngredient.koKoGreenVersion === null &&
      productIngredient.koKoOrangeVersion === null &&
      productIngredient.koKoBlueVersion === null
    );

    const lensStyle = {
      backgroundColor: 'rgba(0,0,0,0.1)',
    };

    const enlargedImageContainerStyle = {
      marginLeft: 20,
      border: 0,
      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    };

    const photoSizes = productIngredient.photo.sizes.sizes;

    return (
      <Fragment>
        <ContainerOverlay />
        <Container
          animation={this.state.containerState}
          ref={this.containerRef}
        >
          <Layout>
            <ProductDescriptionHeader>
              <TitleGroup>
                <H3>{productIngredient.series}</H3>
                <H3>{productIngredient.type}</H3>
              </TitleGroup>
              <BackLink onClick={this.close}>
                <img src={backSvg} alt="Go back to the Product" />
              </BackLink>
            </ProductDescriptionHeader>
            <ImageAndDescription id={'ImageAndDescription'}>
              <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}>
                {(matches): JSX.Element | null =>
                  matches ? (
                    <ImageContainer>
                      <ReactImageMagnify
                        lensStyle={lensStyle}
                        enlargedImagePosition="beside"
                        enlargedImageContainerStyle={
                          enlargedImageContainerStyle
                        }
                        {...{
                          smallImage: {
                            alt: productIngredient.title,
                            isFluidWidth: true,
                            src: imageSrc,
                            srcSet: imageSetSrc,
                            sizes: photoSizes,
                          },
                          largeImage: {
                            src: `${productIngredient.photo.file.url}`,
                            width: largeSize,
                            height: largeSize,
                          },
                          enlargedImageStyle: {
                            minWidth: largeSize,
                            minHeight: largeSize,
                          },
                        }}
                      />
                    </ImageContainer>
                  ) : (
                    <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}>
                      {(matches): JSX.Element | null =>
                        matches ? (
                          <ImageContainerTablet>
                            <IngredientImage
                              src={`${productIngredient.photo.file.url}?fm=jpg&fl=progressive&fit=fill&w=448`}
                            />
                          </ImageContainerTablet>
                        ) : (
                          <ImageContainerMobile>
                            <IngredientImage
                              src={`${productIngredient.photo.file.url}?fm=jpg&fl=progressive&fit=fill&w=315`}
                            />
                          </ImageContainerMobile>
                        )
                      }
                    </MediaQuery>
                  )
                }
              </MediaQuery>
              <IngredientDescription>
                <P>{productIngredient.description.description}</P>
                {shouldColorChoiceBeVisible && (
                  <div
                    style={{
                      display: 'flex',
                      height: '40px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '31px',
                      marginBottom: '27px',
                    }}
                  >
                    {this.getGreenColorCircle()}
                    {this.getOrangeColorCircle()}
                    {this.getBlueColorCircle()}
                  </div>
                )}
              </IngredientDescription>
            </ImageAndDescription>
            <ProductCare>
              <ProductCareInstructionTitle>
                {productIngredient.careInstructionTitle}
              </ProductCareInstructionTitle>
              <ProductCareInstructions>
                {productIngredient.careInstructions.map(
                  (instruction: Record<string, any>): JSX.Element => {
                    return (
                      <ProductCareInstruction key={instruction.id}>
                        <img
                          style={{ verticalAlign: 'middle' }}
                          src={instruction.pictogram.file.url}
                          width="30px"
                          alt={instruction.description}
                        />
                        <CareInstruction>
                          {instruction.description}
                        </CareInstruction>
                      </ProductCareInstruction>
                    );
                  }
                )}
              </ProductCareInstructions>
            </ProductCare>
          </Layout>
        </Container>
      </Fragment>
    );
  }
}

const ContainerOverlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  background: 'rgba(0,0,0,0.7)',
  zIndex: 999,
});

const Container = styled.div(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: '#ffffff',
    zIndex: 1000,
    transition: 'transform 0.7s ease',
    transform: 'translate(-100%, 0)',
    overflow: 'hidden',
    [MEDIA_QUERY.TABLET]: {
      transitionDuration: '0.5s',
    },
    [MEDIA_QUERY.MOBILE]: {
      transitionDuration: '0.3s',
    },
  },
  ({ animation }) => {
    switch (animation) {
      case 'opening': {
        return {
          transform: 'translate(0, 0)',
          overflow: 'hidden',
        };
      }
      case 'opened': {
        return {
          transform: 'translate(0, 0)',
          overflow: 'auto',
        };
      }
      case 'closing': {
        return {
          transform: 'translate(-100%, 0)',
          overflow: 'hidden',
        };
      }
      case 'closed': {
        return {
          transform: 'translate(-100%, 0)',
          overflow: 'hidden',
        };
      }
      default: {
        return {};
      }
    }
  }
);

const Layout = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 12px;
  @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const ProductDescriptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const TitleGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const P = styled.p`
  font-family: Arial;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #232425;
`;

const IngredientDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  align-items: flex-start;
  @media (max-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    flex-direction: column-reverse;
    align-items: center;
    padding-left: 0px;
  }
`;

const IngredientImage = styled.img({
  height: '100%',
});

const ImageAndDescription = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: solid 1px black;

  @media (max-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    padding: 20px;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '448px',
  width: 'auto',
  '& > div:first-child': {
    height: '100%!important',
    '& > img:first-child': {
      height: '100%!important',
      width: 'auto!important',
    },
  },
});

const ImageContainerTablet = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  height: '448px',
  maxHeight: '448px',
  overflow: 'hidden',
});

const ImageContainerMobile = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  height: '315px',
  maxHeight: '315px',
  overflow: 'hidden',
});

const ProductCare = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-top: solid 1px black;

  @media (max-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    flex-direction: column;
  }
`;

const CareInstruction = styled.span`
  font-family: Arial;
  font-size: 16px;
  line-height: 1.5;
  color: #232425;
  margin-left: 15px;
  @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    font-size: 18px;
    line-height: 1.33;
  }
`;

const ProductCareInstructionTitle = styled.h3`
  flex: 1;
  font-family: Arial;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  color: #232425;

  @media (max-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    font-size: 16px;
    margin-left: 20px;
  }
`;

const ProductCareInstructions = styled.div`
  flex: 1;
  padding-top: 20px;
  padding-bottom: 40px;

  @media (max-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    padding-left: 20px;
    padding-top: 0px;
  }
`;

const ProductCareInstruction = styled.div`
  padding: 10px;
`;

const SelectedColorKoKo = styled.div`
  width: 34px;
  height: 34px;
  border: solid 2px #7a7a7a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const GreenCircle = styled(GreenNumberCircle)`
  margin-left: 5px;
  display: flex;
  text-align: center;
`;

const OrangeCircle = styled(OrangeNumberCircle)`
  margin-left: 5px;
  display: flex;
  text-align: center;
`;

const BlueCircle = styled(BlueNumberCircle)`
  margin-left: 5px;
  display: flex;
  text-align: center;
`;

const KoKoCirclesContainer = styled.div`
  margin-right: 25px;
`;

const BackLink = styled.span({
  opacity: 1,
  cursor: 'pointer',
  transition: 'opacity 0.4s ease',
  ':hover': {
    opacity: 0.4,
  },
});

export default ProductIngredientPopup;
