import React from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import * as Icon from '../../assets/icons';
import {
  COLORS,
  EaseInOutTransition,
  MEDIA_QUERY,
  MEDIA_QUERY_MIN_RESOLUTIONS,
} from '../../commonStyles';
import { formatPrice } from '../../functions/utils';
import { i18n } from '../../translations';
import { Ingredients } from './Ingredients';
import { useEnableShopping } from '../../functions/utils/hooks';
import { SizeType, ProductVariant } from '../../types/basicTypes';

export type VariantSize = {
  variantIndex: number;
  size: string;
};

interface Props {
  lang: string;
  onClickMinus: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onClickPlus: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickAddToShoppingCart: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  productVariant: ProductVariant;
  isProductKoko: boolean;
  current: boolean;
  sizeType: SizeType;
  sizes: VariantSize[];
  changeVariantSize: (size: VariantSize) => void;
  selectedVariantIndex: number;
}

const SizeSelectorContainer = styled.div<{
  fill?: boolean;
  marginBottom?: boolean;
}>(
  {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ({ fill, marginBottom = false }) => ({
    justifyContent: fill ? 'space-between' : 'center',
    marginBottom: marginBottom ? '16px' : '0px',
  })
);

const StandardSizeSelectorContainer = styled(SizeSelectorContainer)({
  button: {
    borderWidth: '2px',
    zIndex: 2,
  },
  [MEDIA_QUERY.MOBILE]: {
    button: {
      margin: '0 8px',
      fontSize: '24px',
      height: '48px',
      width: '48px',
    },
  },
});

const FixedSizeTitle = styled.div({
  fontWeight: 'bold',
  textAlign: 'center',
  width: '100%',
  fontSize: '36px',
  height: '60px',
});

const NumberOfUsersInformation = styled.div({
  fontWeight: 'bold',
  fontSize: '36px',
});

export const DetailsVariant: React.FC<Props> = ({
  lang,
  onClickMinus,
  onClickPlus,
  onClickAddToShoppingCart,
  productVariant,
  isProductKoko,
  current,
  openProductIngredientPopup,
  sizeType,
  sizes,
  changeVariantSize,
  selectedVariantIndex,
}) => {
  const __ = i18n(lang);
  const enableShopping = useEnableShopping();
  const price = formatPrice(productVariant.price / 100);
  const priceText = `${price} ${__('shoppingCart.eurPerMonth')}`;

  const NumberOfUsersSizeSelector = () => (
    <SizeSelectorContainer fill>
      <Button onClick={onClickMinus}>
        <Icon.Minus />
      </Button>
      <NumberOfUsersInformation>
        {__('productPicker.forPersonsPre')}
        {productVariant.size}
        {__('productPicker.forPersonsPost')}
      </NumberOfUsersInformation>{' '}
      <Button onClick={onClickPlus}>
        <Icon.Plus />
      </Button>
    </SizeSelectorContainer>
  );

  const FixedSizeSelector = () => (
    <SizeSelectorContainer>
      <FixedSizeTitle> {__('productPicker.fixed')}</FixedSizeTitle>
    </SizeSelectorContainer>
  );

  const StandardSizeSelector = () => (
    <StandardSizeSelectorContainer marginBottom>
      {sizes.map(size => (
        <Button
          inactiveColor={COLORS.DUSTY_GREY}
          borderColor={COLORS.DUSTY_GREY}
          active={selectedVariantIndex === size.variantIndex}
          enableMargin
          enableBorder
          key={`${productVariant.id}-${size.variantIndex}`}
          onClick={() => changeVariantSize(size)}
        >
          {size.size}
        </Button>
      ))}
    </StandardSizeSelectorContainer>
  );

  const SizeSelector = () => {
    switch (sizeType) {
      case SizeType.Standard:
        return <StandardSizeSelector />;
      case SizeType.Fixed:
        return <FixedSizeSelector />;
      case SizeType.NumberOfUsers:
        return <NumberOfUsersSizeSelector />;
      default:
        return <FixedSizeSelector />;
    }
  };

  return (
    <DetailsVariantContainer current={current}>
      <PriceShoppingCartWrapper current={current}>
        <PriceWrapper>
          <SizeSelector />
          <PriceDetails>
            <div>{priceText}</div>
          </PriceDetails>
        </PriceWrapper>
        <MediaQuery minWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}>
          {(matches): JSX.Element | null =>
            matches ? (
              <Ingredients
                isProductKoko={isProductKoko}
                productVariant={productVariant}
                openProductIngredientPopup={openProductIngredientPopup}
              />
            ) : null
          }
        </MediaQuery>
        {enableShopping && (
          <AddToShoppingCartButton onClick={onClickAddToShoppingCart}>
            <AddToShoppingCartContainer>
              {__('addToShoppingCart').toUpperCase()}
              <ShoppingCart>
                <Icon.ShoppingCartBold />
              </ShoppingCart>
            </AddToShoppingCartContainer>
          </AddToShoppingCartButton>
        )}
      </PriceShoppingCartWrapper>
      <MediaQuery maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP - 1}>
        {(matches): JSX.Element | null => {
          if (matches) {
            return (
              <RectangleWithIngredientsForMobile current={current}>
                <b>{__('productPicker.contains')}</b>
                <Ingredients
                  isProductKoko={isProductKoko}
                  productVariant={productVariant}
                  openProductIngredientPopup={openProductIngredientPopup}
                />
              </RectangleWithIngredientsForMobile>
            );
          } else return null;
        }}
      </MediaQuery>
    </DetailsVariantContainer>
  );
};

const DetailsVariantContainer = styled.div<{ current: boolean }>`
  transition: ${EaseInOutTransition('background-color')};
  background-color: ${COLORS.MERCURY_GREY};
  font-family: Arial;
  display: flex;
  flex-direction: column;
  @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP}px) {
    font-size: 18px;
    padding: 32px 88px;
    width: 100%;
  }
  ${MEDIA_QUERY.TABLET_AND_MOBILE} {
    z-index: 10;
    background-color: ${props =>
      props.current ? COLORS.MERCURY_GREY : COLORS.WHITE};
  }
`;

const PriceShoppingCartWrapper = styled.div<{ current: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${MEDIA_QUERY.MOBILE} {
    padding: 14px 14px 30px 14px;
  }
  ${MEDIA_QUERY.TABLET_AND_MOBILE} {
    transition: ${EaseInOutTransition('opacity')};
    opacity: ${props => (props.current ? 1 : 0)};
  }
  ${MEDIA_QUERY.TABLET} {
    padding: 32px;
  }
`;

const Button = styled.button<{
  enableBorder?: boolean;
  active?: boolean;
  enableMargin?: boolean;
  activeColor?: string;
  inactiveColor?: string;
  borderColor?: string;
}>(
  {
    cursor: 'pointer',
    width: '60px',
    height: '60px',
    outline: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    borderRadius: '50%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '31px',
    fontWeight: 'bold',
    transition:
      'background-color 0.3s linear, color 0.3s linear, border-color 0.3s linear',
    ':hover, :active': {
      backgroundColor: COLORS.WHITE,
      color: COLORS.BLACK,
      borderColor: 'transparent',
    },
    [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
      width: '72px',
      height: '72px',
      flex: '0 0 72px',
      display: 'flex',
    },
  },
  ({
    enableBorder,
    enableMargin,
    active,
    borderColor = COLORS.ALTO_GREY,
    inactiveColor = COLORS.ALTO_GREY,
    activeColor = COLORS.BLACK,
  }) => ({
    border: enableBorder ? '3px solid' : 'none',
    borderColor: enableBorder && !active ? borderColor : 'transparent',
    backgroundColor: active ? COLORS.WHITE : 'transparent',
    color: active ? activeColor : inactiveColor,
    margin: enableMargin ? '0 16px' : '0',
  })
);

const PriceDetails = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-align: center;
  white-space: nowrap;
  line-height: 1.25;
  width: 100%;
`;

const hoverState = `
    background-color: ${COLORS.LIGHT_DARK_GREY};
    border-color: ${COLORS.LIGHT_DARK_GREY};
    color: ${COLORS.WHITE};
    transition: all 0.2s linear;
    path,
    rect {
      transition: stroke 0.2s linear;
      stroke: ${COLORS.WHITE};
    }
`;

const AddToShoppingCartButton = styled.button`
  background-color: white;
  margin-top: auto;
  cursor: pointer;
  border: solid 2px #dadada;
  box-sizing: border-box;
  transition: all 0.2s linear;
  :active {
    ${hoverState}
  }
  @media (hover: hover) {
    @media not all and (any-pointer: coarse) {
      :hover {
        ${hoverState}
      }
    }
  }
`;

const AddToShoppingCartContainer = styled.div`
  font-family: Arial;
  letter-spacing: 0.2px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  padding: 0 19px;
  line-height: 42px;
  height: 42px;
  justify-content: left;
  align-items: center;
  position: relative;
  @media (min-width: ${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}px) {
    font-size: 22px;
    line-height: 68px;
    height: 68px;
    letter-spacing: 0.5px;
    padding: 0 16px;
  }
`;

const RectangleWithIngredientsForMobile = styled.div<{ current: boolean }>`
  background-color: #fafbfc;
  border: solid 1px #f0f0ef;
  padding: 30px 15px 15px 15px;
  font-size: 16px;
  position: relative;
  ${MEDIA_QUERY.TABLET_AND_MOBILE} {
    transition: ${EaseInOutTransition('opacity')};
    opacity: ${props => (props.current ? 1 : 0)};
    box-shadow: 0px 35px 120px -55px rgba(0, 0, 0, 0.4);
  }
  ${MEDIA_QUERY.MOBILE} {
    padding-top: 16px;
  }
`;

const ShoppingCart = styled.div`
  display: flex;
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  ${MEDIA_QUERY.MOBILE} {
    transform: translateY(-50%) scale(0.62);
    right: 10px;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  ${MEDIA_QUERY.TABLET} {
    margin-bottom: 36px;
  }
  ${MEDIA_QUERY.MOBILE} {
    margin-bottom: 32px;
  }
`;
