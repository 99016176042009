export type ChildMarkdownRemark = {
  html: string;
};

export type Link = {
  url: string;
};

export type Image = {
  file: Link;
};

export type Photo = {
  file: Link;
  sizes: {
    sizes: string;
  };
};

export type Description = {
  description: string;
};

export type Category = {
  name: string;
  id: string;
  contentful_id: string;
  image: Image;
};

// Unfortunately, Contentful doesn't allow to change the value of the selection, so we are
// left with the contents of the selected label.
export enum SizeType {
  NumberOfUsers = 'By number of users',
  Fixed = 'Fixed',
  Standard = 'Small, Medium, Large',
}

export type Product = {
  images: Image[];
  price: number;
  sizeType: SizeType;
  selectedVariant: number;
  skuCode: string;
  title: string;
  slug: string;
  id: string;
  variants: ProductVariant[];
  basketImage: Image;
  category: Category;
  node_locale: string;
};

export type ProductVariant = {
  id: string;
  name: string;
  skuCode: string;
  price: number;
  net: number;
  vat: number;
  dishCollection: DishCollection;
  product: Product;
  images: Image[];
  size: string;
};

export type DishCollection = {
  title: string;
  amount: number;
  dish: Dish;
};

export type Dish = {
  id: string;
  title: string;
  slug: string;
  type: string;
  photo: Photo;
  description: string;
  careInstructionTitle: string;
  careInstructions: CareInstruction[];
  series: string;
};

export type CareInstruction = {
  id: string;
  description: string;
  pictogram: Photo;
};

export type BasketProduct = {
  id: string;
  skuCode: string;
  name: string;
  slug: string;
  price: number;
  dishCollection: [];
  product: object;
  images: Image[];
};

export type PageContext = {
  language: string;
  basket: [object];
};

export type Props = {
  pageContext: PageContext;
};

export type Recommendation = {
  id: string;
  item: ProductVariant;
  recommendations: ProductVariant[];
  title: string;
};
