import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import * as Icon from '../../assets/icons';
import {
  EaseInOutTransition,
  MEDIA_QUERY,
  MEDIA_QUERY_MIN_RESOLUTIONS,
} from '../../commonStyles';
import { SwipeIndicators } from './SwipeIndicators';
import { Product } from './types';

interface Props {
  isFirst: boolean;
  isLast: boolean;
  current: boolean;
  product: Product;
  selectedVariantIndex: number;
}

export const ImageVariant: React.FC<Props> = ({
  isFirst,
  isLast,
  product,
  selectedVariantIndex,
  current,
  openProductDescriptionPopup,
}) => {
  return (
    <ImageVariantContainer>
      <ImageVariantImgContainer>
        {product.variant.map(({ id, images, name }, index) => (
          <ImageVariantImg
            active={index === selectedVariantIndex}
            key={id}
            src={`${images[0].file.url}?fm=jpg&fl=progressive&fit=fill&w=${MEDIA_QUERY_MIN_RESOLUTIONS.TABLET}`}
            alt={name}
          />
        ))}
      </ImageVariantImgContainer>
      <MediaQuery maxWidth={MEDIA_QUERY_MIN_RESOLUTIONS.DESKTOP - 1}>
        {matches =>
          matches ? (
            <SwipeIndicators
              active={current}
              isFirst={isFirst}
              isLast={isLast}
              timeout={2000}
            />
          ) : null
        }
      </MediaQuery>
      <InfoLink current={current} onClick={openProductDescriptionPopup}>
        <Icon.InfoGrey />
      </InfoLink>
    </ImageVariantContainer>
  );
};

const InfoLink = styled.span<{ current: boolean }>`
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
  transition: ${EaseInOutTransition('opacity')};
  ${MEDIA_QUERY.TABLET_AND_MOBILE} {
    opacity: ${props => (props.current ? 1 : 0)};
  }
  ${MEDIA_QUERY.TABLET} {
    left: 32px;
    top: 32px;
  }
  ${MEDIA_QUERY.DESKTOP} {
    left: 24px;
    top: 24px;
  }
  path {
    transition: opacity 0.2s linear;
  }
  &:hover {
    path {
      opacity: 0.8;
    }
  }
`;

const ImageVariantContainer = styled.div`
  position: relative;
  ${MEDIA_QUERY.DESKTOP} {
    flex: 0 0 auto;
    display: flex;
    flex-basis: 480px;
    width: 480px;
    height: 480px;
  }
`;

const ImageVariantImgContainer = styled.div`
  width: 100%;
  position: relative;
  :after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${MEDIA_QUERY.SMALL_MOBILE} {
    height: 33vh;
    img {
      object-fit: cover;
    }
  }
  ${MEDIA_QUERY.TABLET} {
    height: 35vh;
    img {
      object-fit: cover;
    }
  }
`;

interface ImageVariantImgProps {
  active: boolean;
}

const ImageVariantImg = styled.img<ImageVariantImgProps>(props => ({
  verticalAlign: 'middle',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  opacity: props.active ? 1 : 0,
  transition: 'opacity 0.4s ease',
}));
