import React from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash/fp';

const IconContainer: React.FC<{ show: boolean }> = ({ show, children }) => (
  <span style={{ opacity: show ? 1 : 0 }}>{children}</span>
);

const IconArrowLeft: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="28"
    viewBox="0 0 15 28"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M17 29h-30V-1h30z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M14.599 26.047c.24.244.32.488.32.813 0 .326-.08.57-.32.814-.241.245-.482.326-.803.326-.321 0-.562-.081-.803-.326L.388 14.814C.147 14.57.067 14.326.067 14c0-.326.08-.57.32-.814L13.074.326c.241-.245.482-.326.803-.326.321 0 .562.081.803.326.24.244.321.488.321.814 0 .325-.08.57-.321.813L3.198 14l11.4 12.047z"
      />
    </g>
  </svg>
);

const IconArrowRight: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="28"
    viewBox="0 0 15 28"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-2-1h30v30H-2z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M.401 1.953c-.24-.244-.32-.488-.32-.813C.08.814.16.57.4.326.642.08.883 0 1.204 0c.321 0 .562.081.803.326l12.605 12.86c.241.244.321.488.321.814 0 .326-.08.57-.32.814L1.926 27.674c-.241.245-.482.326-.803.326-.321 0-.562-.081-.803-.326C.081 27.43 0 27.186 0 26.86c0-.325.08-.57.321-.813L11.802 14 .402 1.953z"
      />
    </g>
  </svg>
);

export const SwipeIndicatorsContainer = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s linear;
  padding: 15px;
  opacity: ${props => (props.show ? 1 : 0)};
`;

interface Props {
  timeout: number;
  active: boolean;
  isFirst: boolean;
  isLast: boolean;
}

export const SwipeIndicators: React.FC<Props> = ({
  active,
  timeout,
  isFirst,
  isLast,
}) => {
  const [show, setShow] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    let timerID: number;
    setShow(active);

    if (!isNil(active) && mounted) {
      timerID = setTimeout(() => {
        setShow(false);
      }, timeout);
    }
    return () => {
      if (timerID) {
        clearTimeout(timerID);
      }
    };
  }, [active, mounted]);

  React.useEffect(() => {
    let timerID: number;
    setTimeout(() => {
      setMounted(true);
    }, 1000);
    return () => {
      if (timerID) {
        clearTimeout(timerID);
      }
    };
  }, []);

  return (
    <SwipeIndicatorsContainer show={show && mounted}>
      <IconContainer show={!isFirst}>
        <IconArrowLeft />
      </IconContainer>
      <IconContainer show={!isLast}>
        <IconArrowRight />
      </IconContainer>
    </SwipeIndicatorsContainer>
  );
};
