import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Category, Product } from '../../types/basicTypes';
import { COLORS, CONTAINERS, MEDIA_QUERY } from '../../commonStyles';
import * as Icons from '../../assets/icons';
import { isOverflown } from '../../functions/utils';

const ProductPickerProductContainer = styled.button<{ active: boolean }>(
  {
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    fontSize: '14px',
    padding: '16px',
    backgroundColor: COLORS.WHITE,
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: COLORS.LIGHT_BLACK,
    cursor: 'pointer',
    transition: 'color 0.3s linear',
  },
  ({ active }) => {
    const activeStyles = { color: COLORS.BLACK };
    return active ? activeStyles : {};
  }
);

const ProductPickerMenuProductsContainer = styled.div({
  display: 'none',
  backgroundColor: COLORS.WHITE,
  borderBottom: `solid 0.5px ${COLORS.LIGHT_BLACK}`,
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    display: 'flex',
  },
  [MEDIA_QUERY.DESKTOP]: {
    maxWidth: CONTAINERS.DESKTOP,
    margin: 'auto',
  },
});

const ProductsContainer = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
});

const ArrowsContainer = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  svg: {
    height: '16px',
  },
});

const ArrowButton = styled.div<{ rotate: number }>(
  {
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    svg: {
      path: {
        fill: COLORS.BLACK,
      },
    },
  },
  ({ rotate }) => ({
    transform: `rotate(${rotate}deg)`,
  })
);

type ProductPickerProductsProps = {
  products: Product[];
  activeProduct: Product;
  activeCategory: Category;
  onClick: (product: Product) => void;
};

const SCROLL_SIZE = 100;

export const ProductPickerMenuProducts: React.FC<ProductPickerProductsProps> = ({
  products,
  onClick,
  activeProduct,
  activeCategory,
}) => {
  // Show arrows to let user know that this menu is scrollable
  // if there are too many products and overflow is activated
  const [doesOverflow, setOverflow] = useState(false);
  const productsContainer = useRef<Element>(null);

  const onRefChange = useCallback(
    node => {
      if (node) {
        setOverflow(isOverflown(node));
        productsContainer.current = node;
      }
    },
    [activeCategory]
  );

  const scrollRight = () => {
    if (productsContainer) {
      productsContainer.current!.scrollLeft += SCROLL_SIZE;
    }
  };

  const scrollLeft = () => {
    if (productsContainer) {
      productsContainer.current!.scrollLeft -= SCROLL_SIZE;
    }
  };

  return (
    <ProductPickerMenuProductsContainer>
      <ProductsContainer ref={onRefChange}>
        {products.map(product => (
          <ProductPickerProductContainer
            key={product.id}
            onClick={() => {
              onClick(product);
            }}
            active={activeProduct.id === product.id}
          >
            {product.title}
          </ProductPickerProductContainer>
        ))}
      </ProductsContainer>
      {doesOverflow && (
        <ArrowsContainer>
          <ArrowButton onClick={scrollLeft} rotate={180}>
            <Icons.Arrow />
          </ArrowButton>
          <ArrowButton onClick={scrollRight} rotate={0}>
            <Icons.Arrow />
          </ArrowButton>
        </ArrowsContainer>
      )}
    </ProductPickerMenuProductsContainer>
  );
};
