import { productsStore } from '../../storage/global-state';

export const useAddToShoppingCart = ({
  product,
  selectedVariant,
}: {
  product: Record<string, any>;
  selectedVariant: number;
}) => {
  const [state, actions] = productsStore();
  const addToshoppingCart = () => {
    const { variant } = product;
    const { skuCode, price } = variant[selectedVariant];
    const newItem = {
      skuCode,
      price,
    };

    actions.saveProducts(state.products.concat(newItem));
  };

  return addToshoppingCart;
};

export const useAddToShoppingCartSku = (newItem: {
  skuCode: string;
  price: number;
}) => {
  const [state, actions] = productsStore();

  const addToshoppingCart = () => {
    actions.saveProducts(state.products.concat(newItem));
  };

  return addToshoppingCart;
};
