import React from 'react';
import _ from 'lodash';
import { ProductVariant } from './types';
import { KokoTypes } from '../../commonStyles';

const getAllTypesOfDishes = (dishCollection: Record<string, any>): string[] => {
  const allDishesTypes = dishCollection.reduce(
    (previousValue: string[], currentValue: Record<any, any>): string[] => {
      return previousValue.concat(currentValue.dish.type);
    },
    []
  );
  return _.uniq(allDishesTypes);
};

const removeSpacesAndCommas = (string: string): string =>
  string.replace(/[ ,.]/g, '');

const getAmountOfAllDishesOfType = (
  type: string,
  dishCollection: Record<any, any>
): number => {
  return dishCollection.reduce(
    (previousValue: number, currentValue: Record<any, any>): number => {
      if (currentValue.dish.type === type) {
        return currentValue.amount + previousValue;
      }
      return previousValue;
    },
    0
  );
};

const getAmountOfASpecificColorDishes = (
  type: string,
  dishCollection: Record<any, any>,
  color: string
): number => {
  return dishCollection.reduce(
    (previousValue: number, currentValue: Record<any, any>): number => {
      const colorOfDish = currentValue.dish.kokoType;
      if (colorOfDish === color && currentValue.dish.type === type) {
        return currentValue.amount + previousValue;
      }
      return previousValue;
    },
    0
  );
};

const getSlugOfADishWithSpecificColor = (
  type: string,
  dishCollection: Record<any, any>,
  desiredColor: string
): string => {
  const desiredDishWithAmount = dishCollection.find(
    (dishWithAmount: Record<any, any>): boolean => {
      const colorOfDish = dishWithAmount.dish.kokoType;
      const typeOfDish = dishWithAmount.dish.type;
      return colorOfDish === desiredColor && typeOfDish === type;
    }
  );

  if (desiredDishWithAmount) {
    return desiredDishWithAmount.dish.slug;
  }
  return '';
};

const getIdOfADishWithSpecificColor = (
  type: string,
  dishCollection: Record<any, any>,
  desiredColor: string
): string => {
  const desiredDishWithAmount = dishCollection.find(
    (dishWithAmount: Record<any, any>): boolean => {
      const colorOfDish = dishWithAmount.dish.kokoType;
      const typeOfDish = dishWithAmount.dish.type;
      return colorOfDish === desiredColor && typeOfDish === type;
    }
  );

  if (desiredDishWithAmount) {
    return desiredDishWithAmount.dish.id;
  }
  return '';
};

const populateKoKoModel = (
  dishCollection: Record<any, any>
): Record<string, any> => {
  const allTypesOfDishes = getAllTypesOfDishes(dishCollection);
  const kokoModel = {};

  allTypesOfDishes.forEach((typeOfDish): void => {
    const typeOfDishWithNoSpacesAndCommas = removeSpacesAndCommas(typeOfDish);
    kokoModel[typeOfDishWithNoSpacesAndCommas] = {};
    kokoModel[typeOfDishWithNoSpacesAndCommas].typeOfDish = typeOfDish;
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].amountTotal = getAmountOfAllDishesOfType(typeOfDish, dishCollection);
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].amountOfBlueDishes = getAmountOfASpecificColorDishes(
      typeOfDish,
      dishCollection,
      KokoTypes.blueberry
    );
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].amountOfOrangeDishes = getAmountOfASpecificColorDishes(
      typeOfDish,
      dishCollection,
      KokoTypes.cantaloupe
    );
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].amountOfGreenDishes = getAmountOfASpecificColorDishes(
      typeOfDish,
      dishCollection,
      KokoTypes.lime
    );
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].slugToBlueVersion = getSlugOfADishWithSpecificColor(
      typeOfDish,
      dishCollection,
      KokoTypes.blueberry
    );
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].slugToOrangeVersion = getSlugOfADishWithSpecificColor(
      typeOfDish,
      dishCollection,
      KokoTypes.cantaloupe
    );
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].slugToGreenVersion = getSlugOfADishWithSpecificColor(
      typeOfDish,
      dishCollection,
      KokoTypes.lime
    );

    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].idOfBlueVersion = getIdOfADishWithSpecificColor(
      typeOfDish,
      dishCollection,
      KokoTypes.blueberry
    );
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].idOfOrangeVersion = getIdOfADishWithSpecificColor(
      typeOfDish,
      dishCollection,
      KokoTypes.cantaloupe
    );
    kokoModel[
      typeOfDishWithNoSpacesAndCommas
    ].idOfGreenVersion = getIdOfADishWithSpecificColor(
      typeOfDish,
      dishCollection,
      KokoTypes.lime
    );
  });

  return kokoModel;
};

export const useKokoModel = ({
  productVariant,
}: {
  productVariant: ProductVariant;
}) => {
  const kokoModel = React.useMemo(
    () => populateKoKoModel(productVariant.dishCollection),
    [productVariant]
  );
  return kokoModel;
};
