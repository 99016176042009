import { useMemo } from 'react';

export const useIsProductKoko = ({
  product,
}: {
  product: Record<string, any>;
}) => {
  return useMemo(() => {
    return product.title === 'KoKo';
  }, [product.title]);
};
