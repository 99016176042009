import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { CloseModal, NextModal, PreviousModal } from '../assets/icons';

interface ImageModalProps {
  shouldBeVisible: boolean;
  setShouldShowImageModal(time: boolean): void;
  requestNextImage(): void;
  requestPreviousImage(): void;
  imageUrl: string;
}

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: transparent;
  border: none;
`;

const NextButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  background: transparent;
  border: none;
`;

const PreviousButton = styled.button`
  position: absolute;
  left: 0;
  top: 50%;
  cursor: pointer;
  background: transparent;
  border: none;
`;

const ImageModal: React.FC<ImageModalProps> = props => {
  return (
    <Modal
      isOpen={props.shouldBeVisible}
      contentLabel="Image Modal"
      style={{
        overlay: {
          position: 'fixed',
          zIndex: '999',
          overflow: 'hidden',
          backgroundColor: 'rgba(0, 0, 0, 0.66)',
        },
        content: {
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          borderRadius: 'none',
          border: 'none',
          padding: 0,
        },
      }}
    >
      <ModalContainer>
        <img
          src={props.imageUrl}
          alt="fds"
          style={{
            maxWidth: '80%',
            maxHeight: '80%',
            boxShadow: '0px 8px 24px 0px rgba(0,0,0,0.2)',
          }}
        />
        <CloseButton
          onClick={(): void => {
            props.setShouldShowImageModal(false);
          }}
        >
          <CloseModal />
        </CloseButton>
        <NextButton
          onClick={(): void => {
            props.requestNextImage();
          }}
        >
          <NextModal />
        </NextButton>
        <PreviousButton
          onClick={(): void => {
            props.requestPreviousImage();
          }}
        >
          <PreviousModal />
        </PreviousButton>
      </ModalContainer>
    </Modal>
  );
};

export default ImageModal;
