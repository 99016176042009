import styled from 'styled-components';
import { MEDIA_QUERY } from '../../commonStyles';

interface ProductViewContainerProps {
  marginTopDesktop?: string;
}

export const ProductViewContainer = styled.div<ProductViewContainerProps>(
  props => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 8px',
    // because of the box shadow
    marginBottom: '80px',
    [MEDIA_QUERY.MOBILE_AND_TABLET]: {
      maxWidth: '80vw',
    },
    [MEDIA_QUERY.TABLET]: {
      marginTop: '0',
      padding: '0 16px',
    },
    [MEDIA_QUERY.DESKTOP]: {
      maxWidth: '100%',
      padding: '0',
      marginTop:
        props.marginTopDesktop !== undefined ? props.marginTopDesktop : '0',
      marginBottom: '0',
      [`&:last-child`]: {
        paddingRight: 'initial',
      },
    },
  })
);

export const Hr = styled.div`
  display: none;
  border: none;
  border-bottom: solid 1px #d2d2d2;
  margin-top: 70px;
  margin-bottom: 30px;
  width: calc(100% + 96px);
  margin-left: -48px;
  ${MEDIA_QUERY.DESKTOP} {
    display: block;
    :last-child {
      display: none;
    }
  }
`;

export const VariantContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${MEDIA_QUERY.DESKTOP} {
    flex-direction: row;
  }
`;
